define("discourse/plugins/discourse-activity-pub/discourse/models/activity-pub-category", ["exports", "@ember/object", "@ember/object/computed", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _object, _computed, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ActivityPubCategory = _object.default.extend({
    hasActors: (0, _computed.notEmpty)("actors"),
    loadMore() {
      if (!this.loadMoreUrl || this.total <= this.actors.length) {
        return;
      }
      this.set("loadingMore", true);
      return (0, _ajax.ajax)(this.loadMoreUrl).then(response => {
        if (response) {
          this.actors.pushObjects(response.actors);
          this.setProperties({
            loadMoreUrl: response.meta.load_more_url,
            loadingMore: false
          });
        }
      }).catch(_ajaxError.popupAjaxError);
    }
  });
  ActivityPubCategory.reopenClass({
    listActors(categoryId, params, listType) {
      const queryParams = new URLSearchParams();
      if (params.order) {
        queryParams.set("order", params.order);
      }
      if (params.asc) {
        queryParams.set("asc", params.asc);
      }
      const path = `/ap/category/${categoryId}/${listType}`;
      let url = `${path}.json`;
      if (queryParams.size) {
        url += `?${queryParams.toString()}`;
      }
      return (0, _ajax.ajax)(url).catch(_ajaxError.popupAjaxError);
    }
  });
  var _default = _exports.default = ActivityPubCategory;
});