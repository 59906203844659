define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-statistics", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="activity-pub-statistics">
    <span
      class="activity-pub-visibility"
      title={{i18n
        (concat
          "discourse_activity_pub.visibility.description."
          @category.custom_fields.activity_pub_default_visibility
        )
        object_type=@category.activity_pub_post_object_type
      }}
    >
      {{i18n
        (concat
          "discourse_activity_pub.visibility.label."
          @category.activity_pub_default_visibility
        )
      }}
    </span>
    <span
      class="activity-pub-publication-type"
      title={{i18n
        (concat
          "discourse_activity_pub.publication_type.description."
          @category.activity_pub_publication_type
        )
      }}
    >
      {{i18n
        (concat
          "discourse_activity_pub.publication_type.label."
          @category.activity_pub_publication_type
        )
      }}
    </span>
  </div>
  */
  {
    "id": "ooJXeYle",
    "block": "[[[10,0],[14,0,\"activity-pub-statistics\"],[12],[1,\"\\n  \"],[10,1],[14,0,\"activity-pub-visibility\"],[15,\"title\",[28,[37,0],[[28,[37,1],[\"discourse_activity_pub.visibility.description.\",[30,1,[\"custom_fields\",\"activity_pub_default_visibility\"]]],null]],[[\"object_type\"],[[30,1,[\"activity_pub_post_object_type\"]]]]]],[12],[1,\"\\n    \"],[1,[28,[35,0],[[28,[37,1],[\"discourse_activity_pub.visibility.label.\",[30,1,[\"activity_pub_default_visibility\"]]],null]],null]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,1],[14,0,\"activity-pub-publication-type\"],[15,\"title\",[28,[37,0],[[28,[37,1],[\"discourse_activity_pub.publication_type.description.\",[30,1,[\"activity_pub_publication_type\"]]],null]],null]],[12],[1,\"\\n    \"],[1,[28,[35,0],[[28,[37,1],[\"discourse_activity_pub.publication_type.label.\",[30,1,[\"activity_pub_publication_type\"]]],null]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@category\"],false,[\"i18n\",\"concat\"]]",
    "moduleName": "discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-statistics.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});