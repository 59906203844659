define("discourse/plugins/discourse-activity-pub/discourse/models/activity-pub-actor", ["exports", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _object, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ActivityPubActor = _object.default.extend({});
  ActivityPubActor.reopenClass({
    findByHandle(actorId, handle) {
      return (0, _ajax.ajax)({
        url: `/ap/actor/${actorId}/find-by-handle`,
        type: "GET",
        data: {
          handle
        }
      }).then(response => response.actor || false).catch(_ajaxError.popupAjaxError);
    },
    follow(actorId, targetActorId) {
      return (0, _ajax.ajax)({
        url: `/ap/actor/${actorId}/follow`,
        type: "POST",
        data: {
          target_actor_id: targetActorId
        }
      }).then(response => !!response?.success).catch(_ajaxError.popupAjaxError);
    },
    unfollow(actorId, targetActorId) {
      return (0, _ajax.ajax)({
        url: `/ap/actor/${actorId}/follow`,
        type: "DELETE",
        data: {
          target_actor_id: targetActorId
        }
      }).then(response => !!response?.success).catch(_ajaxError.popupAjaxError);
    }
  });
  var _default = _exports.default = ActivityPubActor;
});