define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-publication-type-dropdown", ["exports", "@ember/object", "I18n", "select-kit/components/combo-box"], function (_exports, _object, _I18n, _comboBox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _comboBox.default.extend({
    classNames: ["activity-pub-publication-type-dropdown"],
    nameProperty: "label",
    content: (0, _object.computed)(function () {
      return [{
        id: "first_post",
        label: _I18n.default.t("discourse_activity_pub.publication_type.label.first_post"),
        title: _I18n.default.t("discourse_activity_pub.publication_type.description.first_post")
      }, {
        id: "full_topic",
        label: _I18n.default.t("discourse_activity_pub.publication_type.label.full_topic"),
        title: _I18n.default.t("discourse_activity_pub.publication_type.description.full_topic")
      }];
    }),
    actions: {
      onChange(value) {
        this.onChange?.(value);
      }
    }
  });
});