define("discourse/plugins/discourse-activity-pub/discourse/activity-pub-category-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    this.route("activityPub.category", {
      path: "/ap/category/:category_id",
      resetNamespace: true
    }, function () {
      this.route("followers");
      this.route("follows");
    });
  }
});