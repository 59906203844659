define("discourse/plugins/discourse-activity-pub/discourse/routes/activity-pub-category-follows", ["exports", "@ember/array", "discourse/models/category", "discourse/routes/discourse", "discourse-common/utils/decorators", "discourse/plugins/discourse-activity-pub/discourse/models/activity-pub-category"], function (_exports, _array, _category, _discourse, _decorators, _activityPubCategory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _discourse.default.extend((_obj = {
    queryParams: {
      order: {
        refreshModel: true
      },
      asc: {
        refreshModel: true
      }
    },
    model() {
      const category = this.modelFor("activityPub.category");
      return _category.default.reloadCategoryWithPermissions({
        slug: _category.default.slugFor(category)
      }, this.store, this.site);
    },
    afterModel(model, transition) {
      const category = model;
      if (!category.can_edit) {
        this.router.replaceWith("/404");
        return;
      }
      return _activityPubCategory.default.listActors(category.id, transition.to.queryParams, "follows").then(response => this.setProperties(response));
    },
    setupController(controller, model) {
      controller.setProperties({
        model: _activityPubCategory.default.create({
          category: model,
          actors: (0, _array.A)(this.actors || []),
          loadMoreUrl: this.meta?.load_more_url,
          total: this.meta?.total
        })
      });
    },
    activate() {
      this.messageBus.subscribe("/activity-pub", this.handleMessage);
    },
    deactivate() {
      this.messageBus.unsubscribe("/activity-pub", this.handleMessage);
    },
    handleMessage(data) {
      const model = data.model;
      const category = this.modelFor("activityPub.category");
      if (model && model.type === "category" && model.id === category.id) {
        this.refresh();
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "handleMessage", [_decorators.bind], Object.getOwnPropertyDescriptor(_obj, "handleMessage"), _obj)), _obj));
});