define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-category-banner", ["exports", "@glimmer/component", "@ember/service", "discourse-i18n", "float-kit/components/d-tooltip", "discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-follow-btn", "discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-handle", "@ember/template-factory", "@ember/component"], function (_exports, _component, _service, _discourseI18n, _dTooltip, _activityPubFollowBtn, _activityPubHandle, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _class2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ActivityPubCategoryBanner = _exports.default = (_class = (_class2 = class ActivityPubCategoryBanner extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "site", _descriptor, this);
    }
    get bannerDescription() {
      const visibility1 = this.args.category.activity_pub_default_visibility;
      const publicationType1 = this.args.category.activity_pub_publication_type;
      return _discourseI18n.default.t(`discourse_activity_pub.banner.${visibility1}_${publicationType1}`);
    }
    get responsiveText() {
      return _discourseI18n.default.t("discourse_activity_pub.banner.responsive_text", {
        category_name: this.args.category.name
      });
    }
    get desktopText() {
      return _discourseI18n.default.t("discourse_activity_pub.banner.text", {
        category_name: this.args.category.name
      });
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="activity-pub-category-banner">
        {{#if @category}}
          <div
            class="activity-pub-category-banner-left activity-pub-category-banner-side"
          >
            <DTooltip
              @icon="discourse-activity-pub"
              @content={{this.bannerDescription}}
            />
            <div class="activity-pub-category-banner-text">
              <span class="desktop">{{this.desktopText}}</span>
              <span class="responsive">{{this.responsiveText}}</span>
            </div>
          </div>
          <div
            class="activity-pub-category-banner-right activity-pub-category-banner-side"
          >
            {{#unless this.site.mobileView}}
              <ActivityPubHandle @actor={{@category.activity_pub_actor}} />
            {{/unless}}
            <ActivityPubFollowBtn
              @actor={{@category.activity_pub_actor}}
              @type="follow"
            />
          </div>
        {{/if}}
      </div>
    
  */
  {
    "id": "yulhAZF+",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"activity-pub-category-banner\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"        \"],[10,0],[14,0,\"activity-pub-category-banner-left activity-pub-category-banner-side\"],[12],[1,\"\\n          \"],[8,[32,0],null,[[\"@icon\",\"@content\"],[\"discourse-activity-pub\",[30,0,[\"bannerDescription\"]]]],null],[1,\"\\n          \"],[10,0],[14,0,\"activity-pub-category-banner-text\"],[12],[1,\"\\n            \"],[10,1],[14,0,\"desktop\"],[12],[1,[30,0,[\"desktopText\"]]],[13],[1,\"\\n            \"],[10,1],[14,0,\"responsive\"],[12],[1,[30,0,[\"responsiveText\"]]],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"activity-pub-category-banner-right activity-pub-category-banner-side\"],[12],[1,\"\\n\"],[41,[51,[30,0,[\"site\",\"mobileView\"]]],[[[1,\"            \"],[8,[32,1],null,[[\"@actor\"],[[30,1,[\"activity_pub_actor\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"          \"],[8,[32,2],null,[[\"@actor\",\"@type\"],[[30,1,[\"activity_pub_actor\"]],\"follow\"]],null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@category\"],false,[\"if\",\"unless\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-category-banner.js",
    "scope": () => [_dTooltip.default, _activityPubHandle.default, _activityPubFollowBtn.default],
    "isStrictMode": true
  }), _class2), _class2), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "site", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});